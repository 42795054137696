import {
  Box,
  Collapse,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Select,
  Dialog,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { Button, Input } from "../../Component/Commons";
import { RiSearchLine } from "react-icons/ri";
import { BsFiletypeCsv } from "react-icons/bs";
import { t } from "i18next";
import { VscSettings } from "react-icons/vsc";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../Helper/Theme";
import worker from "../../assets/image/worker.jpg";
import Model_PPE_KIT from "./Model_PPE_KIT";
import {
  getAlertForPpeKitById,
  getAlertForPpeKitList,
} from "../../service/Ppe.service";
import {
  getAllCameraFilter,
  getAllOrgFilter,
  getLocationFilter,
} from "../../service/Filterdata.service";
import { getEventList } from "../../service/Event.service";
import moment from "moment";

const Ppe_kit_detection = () => {
  const title =
    "PPE Kit Detection -  Video Analytics Software Solutions - VISNX";
  const description =
    "Stay informed on cutting-edge AI in video analytics on the VISNX blog. Explore software solutions redefining safety, efficiency & quality control in various sectors.";
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterEvent, setfilterEvent] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [exportFile, setExportFile] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [alrtMsg, setAlrtMsg] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [ppekitDetailsPopup, setppekitDetailsPopup] = useState(false);
  const [selectedPPE_KIT, setselectedPPE_KIT] = useState(null);
  const [ppekitdata, setppekitdata] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [camFilterList, setCamFilterList] = useState([]);
  const [camEventId, setCamEventId] = useState("");
  const [locationFilterList, setLocationFilterList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [filterOrg, setfilterOrg] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [dataCount, setDataCount] = useState(0);
  const [alertDetailData, setAlertDetailData] = useState(null);

  useEffect(() => {
    getDetectionData(
      searchText,
      currentPage,
      selectedLocation,
      selectedOrg,
      camEventId,
      alrtMsg,
      startDate,
      endDate,
      selectedEvent
    );
    handleOrgFiltersDatas();
  }, []);

  const handleChangePage = (value, newPage) => {
    setCurrentPage(newPage);
    getDetectionData(
      searchText,
      newPage,
      selectedLocation,
      selectedOrg,
      camEventId,
      alrtMsg,
      startDate,
      endDate,
      selectedEvent
    );
  };

  const getDetectionData = async (
    search,
    page,
    selectedLocation,
    selectedOrg,
    camEventId,
    alrtMsg,
    startDate,
    endDate,
    selectedEvent
  ) => {
    try {
      setppekitdata([]);
      const response = await getAlertForPpeKitList(
        search,
        page,
        selectedLocation,
        selectedOrg,
        camEventId,
        alrtMsg,
        startDate,
        endDate,
        selectedEvent
      );
      if (response && response.success) {
        setppekitdata(response.data);
        setDataCount(response?.count);
      } else {
        setppekitdata(null);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getAlertData = async (id) => {
    const res = await getAlertForPpeKitById(id);
    if (res && res.success) {
      setAlertDetailData(res.data);
      handleModelOpen();
    }
  };

  useEffect(() => {
    getEventListData();
  }, []);
  const clearFilter = () => {
    setSelectedLocation("");
    setSelectedOrg("");
    setCamEventId("");
    setAlrtMsg("");
    setStartDate("");
    setSelectedEvent("");
    setEndDate("");
    setShowFilter(!showFilter);
    setSearchText("");
    getDetectionData("", 1, "", "", "", "", "", "", "");
  };
  const handleOrgFiltersDatas = async () => {
    try {
      // setLoading(true);
      handleLocationFiltersDatas();
      const { count, data, message, success, totalPage } =
        await getAllOrgFilter();
      if (success) {
        setfilterOrg(data);
      } else {
        setfilterOrg(data);
      }
    } catch (error) {
      setfilterOrg([]);
    } finally {
      // setLoading(false);
    }
  };
  const handleLocationFiltersDatas = async () => {
    try {
      handleCameraFiltersDatas();
      const { count, data, message, success, totalPage } =
        await getLocationFilter();
      if (success) {
        setLocationFilterList(data);
      } else {
        setLocationFilterList(data);
      }
    } catch (error) {
      setLocationFilterList([]);
    }
  };

  const handleCameraFiltersDatas = async () => {
    try {
      // setLoading(true);
      const { count, data, message, success, totalPage } =
        await getAllCameraFilter();
      if (success) {
        setCamFilterList(data);
      } else {
        setCamFilterList(data);
      }
    } catch (error) {
      setCamFilterList([]);
    } finally {
      // setLoading(false);
    }
  };

  const getEventListData = async () => {
    const response = await getEventList();
    if (response && response.success) {
      let data = response.data.map((item) => ({
        name: item.camera_event,
        id: item.id,
      }));
      setfilterEvent(data);
    } else {
      setfilterEvent([]);
    }
  };

  console.log("ppekitdata", ppekitdata);

  const handleClickProfile = (event) => {
    setExportFile(event.currentTarget);
  };

  const searchHandler = (event) => {
    setSearchText(event.target.value);
    getDetectionData(
      event.target.value,
      1,
      selectedLocation,
      selectedOrg,
      camEventId,
      alrtMsg,
      startDate,
      endDate,
      selectedEvent
    );
  };
  const handleCloseProfile = () => {
    setExportFile(null);
  };
  const handleFilterClick = () => {
    setCurrentPage(1);
    setShowFilter(!showFilter);
  };
  const handleSubmitFilter = () => {
    setCurrentPage(1);
    getDetectionData(
      searchText,
      1,
      selectedLocation,
      selectedOrg,
      camEventId,
      alrtMsg,
      startDate,
      endDate,
      selectedEvent
    );
  };

  const closePopup = () => {
    setppekitDetailsPopup(false);
  };

  const handleModelOpen = () => {
    setppekitDetailsPopup(true);
    setselectedPPE_KIT();
  };
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Stack
        direction="row"
        className="table_search_head"
        justifyContent="space-between"
        width="100%"
        sx={{
          marginBottom: { xs: "12px" },
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: "8px",
          alignItems: { xs: "end", sm: "center" },
        }}
      >
        <Box className="search_field">
          <Input
            hiddenLabel
            placeholder={t("SearchforPPE Kit")}
            value={searchText}
            onChange={(event) => searchHandler(event)}
            iconInput={<RiSearchLine style={{ color: "#292929" }} />}
            style={{
              width: { xs: "100%", sm: "300px" },
              "& .MuiInputBase-root": {
                border: "1px solid #151515 !important",
                input: {
                  background: "#151515 !important",
                  padding: "8px 15px",
                },
              },
            }}
          />
        </Box>

        <Menu
          //   anchorEl={exportFile}
          //   open={Boolean(exportFile)}
          onClose={handleCloseProfile}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <MenuItem
            value="csv"
            // onClick={() =>
            //   setIsModalOpen({
            //     open: true,
            //     currentComponent: "exportSuccess",
            //   })
            // }
            // onClick={(e) => handleDownloadFile(e)}
            onMouseUp={handleCloseProfile}
          >
            <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"}>
              <BsFiletypeCsv style={{ fontSize: "18px" }} />
              <div style={{ display: "flex" }}>{t("csv")}</div>
            </Stack>
          </MenuItem>
        </Menu>

        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          {/* <Button buttonName={t("Export")} onClick={handleClickProfile} /> */}
          <Button
            buttonName={
              <VscSettings style={{ fontSize: "19px", margin: "4px 0" }} />
            }
            style={{ minWidth: "25px", padding: "6px 13px" }}
            onClick={handleFilterClick}
          />
        </Stack>
      </Stack>
      {/* filter */}
      <Collapse Collapse in={showFilter}>
        <Stack
          mb={"12px"}
          sx={{ background: "#151515" }}
          flexDirection={"row "}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"flex-end"}
          p={1}
          alignItems={"center"}
        >
          {/* <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("AlertMessage")}:
            </Typography>
            <Input placeholder={t("SearchAlertMessage")} type="text" value={alrtMsg} onChange={(e) => setAlrtMsg(e.target.value)} />
          </Grid> */}
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Location")}:
            </Typography>
            <Select
              name={"loc"}
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.target.value)}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" hidden disabled>
                {t("SelectLocation.")}
              </MenuItem>
              {locationFilterList?.map((data, i) => {
                return (
                  <MenuItem value={data.id} key={i}>
                    {data?.loc_name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Camera")}:
            </Typography>
            <Select
              name={"loc"}
              value={camEventId}
              onChange={(e) => setCamEventId(e.target.value)}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" hidden disabled>
                {t("SelectCamera.")}
              </MenuItem>
              {camFilterList?.map((data, i) => {
                return (
                  <MenuItem value={data.id} key={i}>
                    {data?.camera_name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("OrganizationName")}
            </Typography>
            <Select
              name={"org"}
              value={selectedOrg}
              onChange={(e) => setSelectedOrg(e.target.value)}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" hidden disabled>
                {t("SelectOrganazation.")}
              </MenuItem>
              {filterOrg?.map((data, i) => {
                return (
                  <MenuItem value={data.id} key={i}>
                    {data?.org_name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("Event")}
            </Typography>
            <Select
              name={"org"}
              value={selectedEvent}
              onChange={(e) => setSelectedEvent(e.target.value)}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" hidden disabled>
                {t("SelectEvent")}
              </MenuItem>
              {filterEvent?.map((data, i) => {
                return (
                  <MenuItem value={data.id} key={i}>
                    {data?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("StartDate")}:
            </Typography>
            <Input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Grid>
          <Grid className="filterinput">
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {t("EndDate")}:
            </Typography>
            <Input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              min={startDate}
            />
          </Grid>
          <Box sx={{ mx: 1 }}>
            <Button
              buttonName={t("Filter")}
              onClick={() => handleSubmitFilter()}
            />
          </Box>
          <Box sx={{ mx: 1 }}>
            <Button buttonName={t("Clear")} onClick={() => clearFilter()} />
          </Box>
        </Stack>
      </Collapse>
      <Stack>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>
                  {/* <div style={{ display: 'flex', justifyContent: 'space-between', padding: 0, flexWrap: "wrap", gap: "2px" }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
                                            <span style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                <div className="red"></div>Gloves
                                            </span>
                                            <span style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                <div className="red"></div>Jacket
                                            </span>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
                                            <span style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                <div className="green"></div>Helmet
                                            </span>
                                            <span style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                <div className="green"></div>Shoes
                                            </span>
                                        </div>
                                    </div> */}
                  Categories
                </TableCell>
                <TableCell>LocationName</TableCell>
                <TableCell>Camera</TableCell>
                <TableCell>OrganizationName</TableCell>
                <TableCell>Date&Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ppekitdata?.map((data, index) => (
                <TableRow
                  key={index}
                  onClick={() => getAlertData(data.id)}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell>{data.id}</TableCell>
                  <TableCell>
                    <img
                      src={
                        data?.alerts && data?.alerts[0] && data?.alerts[0].frame
                      }
                      alt="worker"
                      width={40}
                      height={40}
                      style={{ objectFit: "cover" }}
                    />
                  </TableCell>
                  <TableCell>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Grid item xs={6} container direction="column">
                        {data?.alerts?.map((item, idx) => (
                          <Grid item key={idx}>
                            <span
                              style={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className={
                                  item?.detection_choice == "No"
                                    ? "red"
                                    : "green"
                                }
                              ></div>
                              {item?.camera_events_name}
                            </span>
                          </Grid>
                        ))}
                      </Grid>
                      {/* <Grid item xs={6} container direction="column">
                        {data.redcategory.map((item, idx) => (
                          <Grid item key={idx}>
                            <span
                              style={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              <div className="red"></div>
                              {item}
                            </span>
                          </Grid>
                        ))}
                      </Grid> */}
                    </div>
                  </TableCell>
                  <TableCell>
                    {data.alerts &&
                      data.alerts[0] &&
                      data.alerts[0].location_name} - {data.alerts &&
                        data.alerts[0] &&
                        data.alerts[0].area_name}
                  </TableCell>
                  <TableCell>
                    {data.alerts &&
                      data.alerts[0] &&
                      data.alerts[0].camera_name}
                  </TableCell>
                  <TableCell>
                    {data.alerts &&
                      data.alerts[0] &&
                      data.alerts[0].organization_name}
                  </TableCell>
                  <TableCell>
                    {moment(
                      data.alerts && data.alerts[0] && data.alerts[0].created_at
                    ).format("DD-MM-YYYY HH:mm:ss")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Stack
        sx={{ mt: "10px", pb: 2, fontSize: "14px" }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          {`${t("Showing")} ${ppekitdata ? ppekitdata.length : 0}
          ${t("of")} ${dataCount ?? 0} ${t("Results")}`}
        </div>
        <Pagination
          count={Math.ceil(dataCount / pageLimit)}
          page={currentPage}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
      <Dialog
        onClose={closePopup}
        aria-labelledby="Common_modal"
        open={ppekitDetailsPopup}
      >
        <Model_PPE_KIT
          handleClose={closePopup}
          selectedPPE_KIT={selectedPPE_KIT}
          data={alertDetailData}
        />
      </Dialog>
    </HelmetProvider>
  );
};

export default Ppe_kit_detection;
